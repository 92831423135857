// Mx
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    LAYOUT: {
      FOOTER: {
        COPYRIGHT_TEXT: 'Vakva / T4biz',
        ABOUT: "Acerca de",
        HELP_SUPPORT: "Ayuda y Soporte",
        CONTACT: "Contacto"
      },
      TOPBAR: {
        NEW_PARTNER_BUTTON: "Nuevo Socio"
      },
      TOOLBAR: {
        CREATE_BUTTON: "Crear",
        FILTER_BUTTON: "Filtro"
      },
      THEME_SELECTOR: {
        DARK_LINK: "Obscuro",
        LIGHT_LINK: "Claro",
        SYSTEM_LINK: "Sistema"
      },
      INNER_USER_DROPDOWN: {
        NETWORK_LINK_BUTTON: "Mi Organización",
        LOANS_LINK_BUTTON: "Mis Créditos",
        STATEMENTS_LINK_BUTTON: "Estados de Cuenta",
        LANGUAJE_LINK: "Idioma",
        ACCOUNT_SETTINGS_LINK: "Configuración de la cuenta",
        LOGOUT_LINK: "Cerrar Sesión",
        COMING_SOON_MESSAGE: "Esperalo"
      }
    },
    MENU: {
      DASHBOARD: "Dashboard",
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Continuar',
        CANCEL_BUTTON: 'Cancelar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Regresar',
        PRIVACY: 'Privacidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
        WAIT_MESSAGE: "Por favor espere..."
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
        LOGIN_ERROR_DETAIL: "La información introducida no es válida, por favor intenta nuevamente.",
        SIGNUP_TITLE: "Iniciar sesión en Vakva",
        NEW_HERE: "Eres nuevo?",
        FORGOT_PASSWORD: "¿Olvidaste la contraseña?",
        GOOGLE_BUTTON: "Continue with Google"
      },
      FORGOT: {
        TITLE: '¿Olvidaste la contraseña?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Tu cuenta ha sido restablecida satisfactoriamente, inicia sesión para continuar.',
        SEND_CODE_MESSAGE: 'Un nuevo código ha sido enviado a tu email, por favor revisa tu bandeja de entrada.',
        FORGOT_QUESTION: 'Ya haz restablecido tu contraseña?',
        SET_PASSWORD_TITLE: 'Establecer nueva contraseña',
      },
      REGISTER: {
        TITLE: 'Crear una cuenta',
        ACCOUNT_QUESTION: "¿Ya tienes una cuenta?",
        SIGN_IN: "Iniciar sesión",
        SIGN_IN_GOOGLE: "Iniciar sesión con Google",
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        REGISTRATION_ERROR_MESSAGE: "La información de registro es incorrecta",
        AGREE_MESSAGE: "Estoy de acuerdo con los",
        TERMS_LABEL: "Términos y condiciones",
        PHONENUMBER_INVALID: "Por favor ingrese un número de teléfono válido.",
      },
      CONFIRM: {
        TITLE: 'Código de verificación',
        SUBTITLE: 'Ingresa el código de verificación enviado a',
        ENTER_CODE: 'Ingresa tu código de seguridad de 6 dígitos',
        GET_CODE_QUESTION: '¿No recibiste tú código?',
        RESEND: 'Reenviar',
        CONFIRM_ERROR_MESSAGE: 'Ocurrio un error al confirmar tu cuenta, por favor intenta nuevamente.',
        ACCOUNT_CONFIRMED_MESSAGE: 'Tu cuenta ha sido confirmada!, inicia sesión para continuar.',
        RESEND_CODE_MESSAGE: 'El nuevo código ha sido enviado a tu email: {{email}}. Revisa tu bandeja de entrada.',
      },
      INPUT: {
        EMAIL: 'Correo Electrónico',
        FULLNAME: 'Nombre',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar Contraseña',
        USERNAME: 'Nombre de Usuario',
        PHONE: 'Teléfono',
        PASSWORD_INSTRUCTIONS: 'Usa más de 8 caracteres con una mezcla de letras, números y simbolos.',
        BUTTON_OK: 'Continuar',
        BUTTON_CANCEL: 'Cancelar',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        // email
        EMAIL_INVALID: 'El correo electrónico es inválido.',
        EMAIL_REQUIRED: "El correo electrónico es requerido.",
        EMAIL_MIN: 'El correo electrónico debe contener al menos 3 caracteres.',
        EMAIL_MAX: 'El correo electrónico no debe contener más de 360 caracteres.',
        // phone
        PHONE_INVALID: "Por favor ingrese un número de teléfono válido.",
        PHONE_REQUIRED: "El número de teléfono es requerido.",
        PHONE_MIN: 'El número de teléfono debe contener al menos 10 caracteres.',
        PHONE_MAX: 'El número de teléfono no debe contener más de 10 caracteres.',
        PHONE_EXISTS: "El número de teléfono ya existe.",
        // username
        USERNAME_INVALID: "El nombre de usuario es inválido.",
        USERNAME_REQUIRED: "El nombre de usuario es requerido.",
        USERNAME_MIN: 'El nombre de usuario debe contener al menos 3 caracteres.',
        USERNAME_MAX: 'El nombre de usuario no debe contener más de 360 caracteres.',
        USERNAME_EXISTS: "El nombre de usuario ya existe.",
        //password
        PASSWORD_MATCH_MESSAGE: "La contraseña y la confirmación no coinciden.",
        PASSWORD_MIN: 'La contraseña debe contener al menos 8 caracteres.',
        PASSWORD_MAX: 'La contraseña no debe contener más de 100 caracteres.',
        PASSWORD_INVALID: "La contraseña es inválida.",
        PASSWORD_REQUIRED: "La contraseña es requerida.",
        PASSWORD_CONFIRM_REQUIRED: "La confirmación de la contraseña es requerida.",
        PASSWORD_CONFIRM_INVALID: "La confirmación de la contraseña es inválida.",
        PASSWORD_CONFIRM_MIN: 'La contraseña debe contener al menos 8 caracteres.',
        PASSWORD_CONFIRM_MAX: 'La contraseña no debe contener más de 100 caracteres.',

      }
    },
  }
};
