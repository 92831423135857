// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    LAYOUT: {
      FOOTER: {
        COPYRIGHT_TEXT: 'Vakva / T4biz',
        ABOUT: "About",
        HELP_SUPPORT: "Help / Support",
        CONTACT: "Contact"
      },
      TOPBAR: {
        NEW_PARTNER_BUTTON: "New Business Partner"
      },
      TOOLBAR: {
        CREATE_BUTTON: "Create",
        FILTER_BUTTON: "Filter"
      },
      THEME_SELECTOR: {
        DARK_LINK: "Dark",
        LIGHT_LINK: "Light",
        SYSTEM_LINK: "System"
      },
      INNER_USER_DROPDOWN: {
        NETWORK_LINK_BUTTON: "My Network",
        LOANS_LINK_BUTTON: "My Loans",
        STATEMENTS_LINK_BUTTON: "My Statements",
        LANGUAJE_LINK: "Language",
        ACCOUNT_SETTINGS_LINK: "Account Settings",
        LOGOUT_LINK: "Logout",
        COMING_SOON_MESSAGE: "Coming soon"
      }
    },
    MENU: {
      DASHBOARD: "Dashboard",
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Continue',
        CANCEL_BUTTON: 'Cancelar',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        WAIT_MESSAGE: "Please wait..."
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
        LOGIN_ERROR_DETAIL: "The login information you entered is incorrect. Please try again.",
        SIGNUP_TITLE: "Sign In to Vakva",
        NEW_HERE: "New Here?",
        FORGOT_PASSWORD: "Forgotten Password?",
        GOOGLE_BUTTON: "Continue with Google"
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
        SEND_CODE_MESSAGE: 'A new code has been sent to your email. Please check your inbox.',
        FORGOT_QUESTION: 'Have you already reset the password ?',
        SET_PASSWORD_TITLE: 'Setup New Password'
      },
      REGISTER: {
        TITLE: 'Create an Account',
        ACCOUNT_QUESTION: "Already have an account? ",
        SIGN_IN: "Sign in",
        SIGN_IN_GOOGLE: "Sign in with Google",
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        REGISTRATION_ERROR_MESSAGE: "The registration details are incorrect",
        AGREE_MESSAGE: "I Agree the",
        TERMS_LABEL: "terms and conditions",
      },
      CONFIRM: {
        TITLE: 'Two-Factor Verification',
        SUBTITLE: 'Enter the verification code we sent to',
        ENTER_CODE: 'Type your 6 digit security code',
        GET_CODE_QUESTION: 'Didn\'t receive the code?',
        RESEND: 'Resend',
        CONFIRM_ERROR_MESSAGE: 'Occurred an error on confirmation. Please try again.',
        ACCOUNT_CONFIRMED_MESSAGE: 'Your account had been confirmed. Now please SignIn!',
        RESEND_CODE_MESSAGE: 'The code has been resent to your email: {{email}}. Please check your inbox.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
        PHONE: 'Phone Number',
        PASSWORD_INSTRUCTIONS: 'Use 8 or more characters with a mix of letters, numbers & symbols.',
        BUTTON_OK: 'Ok, go ahead',
        BUTTON_CANCEL: 'Cancel',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
        // email
        EMAIL_INVALID: 'The email address is not valid.',
        EMAIL_REQUIRED: 'The email is required.',
        EMAIL_MIN: 'Email should have at least 3 symbols',
        EMAIL_MAX: 'Email should have maximum 360 symbols',
        // phone
        PHONE_INVALID: 'Please enter a valid phone number.',
        PHONE_REQUIRED: 'The phone number is required.',
        PHONE_MIN: 'The phone number should have at least 10 symbols',
        PHONE_MAX: 'The phone number should have maximum 20 symbols',
        PHONE_EXISTS: "The selected phone number exists.",
        // user name
        USERNAME_INVALID: 'The username is not valid.',
        USERNAME_REQUIRED: 'The username is required.',
        USERNAME_MIN: 'The username should have at least 3 symbols',
        USERNAME_MAX: 'The username should have maximum 360 symbols',
        USERNAME_EXISTS: "The selected username exists.",
        //password
        PASSWORD_MATCH_MESSAGE: 'The Passsword and Confirm Password didn\'t match.',
        PASSWORD_MIN: 'The password should have at least 8 symbols',
        PASSWORD_MAX: 'The password should have maximum 100 symbols',
        PASSWORD_INVALID: 'The password is not valid.',
        PASSWORD_REQUIRED: 'The password is required.',
        PASSWORD_CONFIRM_REQUIRED: 'The confirm password is required.',
        PASSWORD_CONFIRM_INVALID: 'The confirm password is invalid.',
        PASSWORD_CONFIRM_MIN: 'The confirm password should have at least 8 symbols',
        PASSWORD_CONFIRM_MAX: 'The confirm password should have maximum 100 symbols',
      }
    },
  }
};
