import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthSession, AuthToken } from '../models/auth.model';
import { SignUpModel } from '../models/signup.model';
import { AuthHTTPService } from './auth-http';
import config from 'src/config.js';
import { Router } from '@angular/router';
import { ISignUpResult } from 'amazon-cognito-identity-js';

export type UserType = AuthSession | undefined;
export type SignUpType = ISignUpResult | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `${config.appVersion}-${config.USERDATA_KEY}`;

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  //share information of current user
  currentUser$: Observable<UserType>;
  currentUserSubject: BehaviorSubject<UserType>;
  //share information of current signup
  currentSignupSubject: BehaviorSubject<SignUpType>;
  currentSignup$: Observable<SignUpType>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  get currentSignupValue(): SignUpType {
    return this.currentSignupSubject.value;
  }

  set currentSignupValue(signup: SignUpType) {
    this.currentSignupSubject.next(signup);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentSignupSubject = new BehaviorSubject<SignUpType>(undefined);
    this.currentSignup$ = this.currentSignupSubject.asObservable();
    //internal subscription
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  /**
   * Sign up a new user
   * @param email
   * @param password
   */
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((loginResult: any) => {
        const result = this.getUserAuthDataMap(loginResult);
        this.setAuthFromLocalStorage(result);
        return result;
      }),
      catchError((err) => {
        throw new Error(err.message);
      }),
      switchMap(() => this.getUserByToken()),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  /**
   * Logout user service
   */
  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.authHttpService.logout();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  /**
   * Singup user service
   */
  signUp(user: SignUpModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .signUp(user)
      .pipe(
      map((confirmSignup: ISignUpResult) => {
        this.isLoadingSubject.next(false);
        this.currentSignupSubject.next(confirmSignup);
        return confirmSignup;
      }),
      catchError((err) => {
        throw new Error(err.message);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  confirmSignUp(username: string, code: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .confirmSignUp(username, code)
      .pipe(
      map((confirmResult: any) => {
        this.isLoadingSubject.next(false);
        if (confirmResult === 'SUCCESS') {
          return true;
        }
      }),
      catchError((err) => {
        throw new Error(err.message);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  resendConfirmationCode(username: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.resendConfirmationCode(username).pipe(
      map((resendResult: any) => {
        this.isLoadingSubject.next(false);
        if (resendResult) {
          return true;
        }
      }),
      catchError((err) => {
        throw new Error(err.message);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();

    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .getUserByToken(auth.authToken.accessToken)
      .pipe(
      map((user: UserType) => {
        if (user) {
          const result = this.getUserAuthDataMap(user);
          this.currentUserSubject.next(result);
          return result;
        } else {
          this.logout();
        }
      }),
      catchError((err) => {
        throw new Error(err.message);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(
        map((forgotResult: any) => {
          this.isLoadingSubject.next(false);
          return true;
        }),
        catchError((err) => {
          throw new Error(err.message);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(username: string, code: string, newPassword: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.resetPassword(username, code, newPassword)
      .pipe(
        map((resetResult: any) => {
          this.isLoadingSubject.next(false);
          console.log(resetResult);
          return true;
        }),
        catchError((err) => {
          throw new Error(err.message);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthSession): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): UserType | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      return undefined;
    }
  }

  getUserAuthDataMap(loginResult: any): AuthSession {
    const result = new AuthSession();
    result.jwtToken = loginResult.signInUserSession.idToken.jwtToken;
    result.userId = loginResult.username;
    result.payload = loginResult.signInUserSession.idToken.payload;
    result.authToken = new AuthToken();
    result.authToken.accessToken = loginResult.signInUserSession.accessToken.jwtToken;
    result.authToken.refreshToken = loginResult.signInUserSession.refreshToken.token;
    result.authToken.tokenUse = loginResult.signInUserSession.accessToken.payload.token_use;
    return result;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
