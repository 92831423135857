export class Address {
  formated: string;
}
export class AuthPayload {
  sub: string;
  website?: string;
  email: string;
  email_verified: boolean;
  address?: Address;
  birthdate?: string;
  gender?: string;
  iss?: string
  phone_number: string;
  phone_number_verified: boolean;
  locale?: string | 'es';
  middle_name?: string;
  aud?: string;
  auth_time?: number;
  name?: string;
  nickname?: string;
  exp: number;
  iat: number;
  family_name?: string;
  picture?: string;
}

export class AuthToken {
  accessToken: string;
  refreshToken: string;
  tokenUse: string;
}
export class AuthSession {
  userId: string;
  jwtToken: string;
  payload: AuthPayload;
  authToken: AuthToken;

}

