import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SignUpModel } from '../../models/signup.model';
import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';

const API_USERS_URL = 'api/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}
  hasMessage(e: any): e is {message: string} {
    return e && typeof e.message == 'string'
  }
  /**
   * Signs up a new user with the SignUpModel class
   * @param signUpModel
   * @returns Observable<ISignUpResult> Represents the result of the sign up with cognito
   */
  signUp({ password, email, phoneNumber, picture }: SignUpModel): Observable<ISignUpResult>{
    try {
      const user = Auth.signUp({
        username: email,
        password,
        attributes: {
          phone_number: phoneNumber, // optional - E.164 number convention
          picture: picture
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: false,
        },
      });
      return from(user);
    } catch (error) {
      throw new Error("VKWAP-AUTH-SIGNUP-ERROR: " + error);
    }
  }

  /**
   * Logs the user in with the username and password
   * @param email
   * @param password
   */
  login(username: string, password: string): Observable<any> {
    try {
      const authResult = Auth.signIn({
        username,
        password,
      });
      return from(authResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-LOGIN-ERROR: " + error);
    }
  }

  /**
   * Confirm SignUp
   */
  confirmSignUp(username: string, code: string): Observable<any> {
    try {
      const confirmResult = Auth.confirmSignUp(
        username, code);
      return from(confirmResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-CONFIRM-ERROR: " + error);
    }
  }

  /**
   * Resend Confirmation Code
   */
  resendConfirmationCode(username: string): Observable<any> {
    try {
      const resendResult = Auth.resendSignUp(username);
      return from(resendResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-RESEND_SINGUP_CODE-ERROR: " + error);
    }
  }
  /**
   * Logs the user in with the username and password
   * @param email
   * @param password
   */
  logout(): Observable<any> {
    try {
      const logoutResult = Auth.signOut();
      return from(logoutResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-LOGOUT-ERROR: " + error);
    }
  }

  /**
   Requests a password reset for the given user.
   @param email The email of the user.
   @returns An observable that emits a boolean indicating whether the request was successful, or an error message if an error occurs.
   */
  forgotPassword(email: string): Observable<boolean> {
    try {
      const forgotResult = Auth.forgotPassword(email);
      return from(forgotResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-FORGOTPASS-ERROR: " + error);
    }
  }


/**
 * Resets the password for the given user.
 * @param username The username of the user.
 * @param code The confirmation code sent to the user's email.
 * @param newPassword The new password for the user.
 * @returns An observable that emits a success message on completion, or an error message if an error occurs.
 */
resetPassword(username: string, code: string, newPassword: string): Observable<string> {
  try {
    const resetPassResult = Auth.forgotPasswordSubmit(username, code, newPassword);
    return from(resetPassResult);
  } catch (error) {
    throw new Error("VKWAP-AUTH-RESETPASS-ERROR: " + error);
  }
}

  getUserByToken(token: string): Observable<any> {
    try {
      const authResult = Auth.currentAuthenticatedUser();
      return from(authResult);
    } catch (error) {
      throw new Error("VKWAP-AUTH-GETUSER-ERROR: " + error);
    }
  }
}
