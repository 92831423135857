
/* eslint-disable no-undef */
const config = {
  AWS_REGION: __AWS_REGION__,
  AWS_COGNITO_IDENTITY_POOL_ID: __COGNITO_IDENTITY_POOL_ID__, // leave blank if not set
  AWS_COGNITO_USER_POOL_ID: __COGNITO_USER_POOL_ID__,
  AWS_COGNITO_CLIENT_ID: __COGNITO_CLIENT_ID__,
  APPSYNC_API_ENDPOINT: {
    graphql_endpoint: __APPSYNC_API__,
    mock: __MOCK_ENABLED__
  },
  // any additional config goes here
  production: (__NODE_ENV__ == 'production' ? true : false),
  environment_name: __NODE_ENV__,
  application_name: __APP_NAME__,
  appVersion: __APP_VERSION__,
  isMockEnabled: __MOCK_ENABLED__,
  USERDATA_KEY: __USERDATA_KEY__,
  sentry_dsn: __SENTRY_DSN__,
};

export default config;
